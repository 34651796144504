(function ($, sr) {
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  const debounce = function (func, threshold, execAsap) {
    let timeout;

    return function debounced() {
      const obj = this; const
        args = arguments;
      function delayed() {
        if (!execAsap) func.apply(obj, args);

        timeout = null;
      }

      if (timeout) clearTimeout(timeout);
      else if (execAsap) func.apply(obj, args);

      timeout = setTimeout(delayed, threshold || 100);
    };
  };

  // smartresize
  jQuery.fn[sr] = function (fn) { return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
}(jQuery, 'smartresize'));

(function ($, sr) {
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  const debounce = function (func, threshold, execAsap) {
    let timeout;

    return function debounced() {
      const obj = this; const
        args = arguments;
      function delayed() {
        if (!execAsap) func.apply(obj, args);

        timeout = null;
      }

      if (timeout) clearTimeout(timeout);
      else if (execAsap) func.apply(obj, args);

      timeout = setTimeout(delayed, threshold || 10);
    };
  };

  // throttle scroll
  jQuery.fn[sr] = function (fn) { return fn ? this.bind('scroll', debounce(fn)) : this.trigger(sr); };
}(jQuery, 'throttle'));

(function ($) {
  // main object
  const ews = {
    $bodyHeight: $('.mobile-nav-overlay').height(),
    $body: $('body'),

    // window height
    $hgt: $(window).height(),
    $wth: $(window).width(),

    // navigation
    $deviceNav: $('#device-nav-toggle'),
    $subNav: $('#navbar-mainnav'),
    $closenNav: $('#close-nav'),
    $mobOverlay: $('.mobile-nav-overlay'),

    // initialize the functions contained within the object
    initialize() {
      const _this = this;

      // initialize below functions
      _this.deviceNav().parsleyValidation();

      $(document).ready(() => {
        function mobileResize() {

        }

        _this.mobileResize = mobileResize;

        mobileResize();

        // debounce resize
        $(window).smartresize(() => {

        });

        $('#how').change(function () {
          const val = $(this).val();

          if (val === 'other') {
            $('#other-container').show();
          } else {
            $('#other-container').hide();
          }
        }).trigger('change');

        // global go back button
        $('.go-back-btn').click(() => {
          window.history.back(-1);

          return false;
        });

        // global open sized window
        $('.open-sized-window').click(function () {
          window.open($(this).attr('href'), 'title', 'width=600, height=400');
          return false;
        });

        // switch img hero image with clicked thumb
        $('.detail-thumb').click(function () {
          const imgThumb = $(this).attr('data-img');

          $('.detail-hero').attr('src', imgThumb);

          return false;
        });
      });
    },

    // Mobile and device navigation hide / reveal
    deviceNav() {
      const _this = this;

      function closeNav() {
        _this.$deviceNav.removeClass('activate');
        _this.$subNav.addClass('fadeout').removeClass('fadein');
        _this.$body.removeClass('overflow');
      }

      _this.$deviceNav.click(function () {
        if ($(this).hasClass('activate')) {
          closeNav();
        } else {
          $(this).addClass('activate');
          _this.$subNav.addClass('fadein').removeClass('fadeout');
          _this.$body.addClass('overflow');
        }

        return false;
      });

      // _this.$subNav.find('a').click(closeNav);

      _this.$mobOverlay.click(closeNav);

      _this.$closenNav.click(closeNav);

      return _this;
    },

    // validation function
    parsleyValidation() {
      const _this = this;

      const parsleyHandler = {

        successClass: 'has-success has-feedback',
        errorClass: 'has-error has-feedback',
        classHandler(el) {
          return el.$element.closest('.form-group');
        },
        errorsWrapper: "<span class='form-feedback'></span>",
        errorTemplate: '<span></span>',
      };
      $('.parsley-validate').parsley(parsleyHandler);

      return _this;
    },

  };

  // initialize object
  jQuery((event) => {
    ews.initialize();
  });
}(jQuery));

// simple ajax request with replace
function simpleAjaxReplace(formid, component, version, task, value, targetClass) {
  var formid = `#${formid}`;
  const ajax_url = `${project_prefix}ajax.php?ajax_component=${component}&ajax_version=${version}&task=${task}&value=${value}`;
  $.post(ajax_url, $(formid).serialize())
    .done((data) => {
      $(targetClass).replaceWith(data);
    });
}

/// / ajax request to be used from anywhere
function getAjaxData(formid, component, version, task, value, targetClass) {
  // alert(formid);
  if (formid !== '') {
    var formid = `#${formid}`;

    var ajax_url = `${project_prefix}ajax.php?ajax_component=${component}&ajax_version=${version}&task=${task}&value=${value}`;

    // alert(product);

    // $.post(ajax_url, { ajax_component: component, ajax_version: version, task: task, value: value, attr1: attr1, attr2: attr2, attr3: attr3, attr4: attr4 })
    $.post(ajax_url, $(formid).serialize())
      .done((data) => {
        $(targetClass).html(data);

        $('.lazy').lazyload({
          effect: 'fadeIn',
        });
      });
  } else {
    var ajax_url = `${project_prefix}ajax.php?ajax_component=${component}&ajax_version=${version}&task=${task}&value=${value}`;

    $.post(ajax_url)
      .done((data) => {
        $(targetClass).html(data);

        // run cart widget if cart

        if (component === 'cart') {
          cartWidget(version);
        }
      });
  }
}

function createCartItem(cartItem) {
  return `<tr id="cart_item_${cartItem.product_id}">
      <td><img class="img-responsive" src="${cartItem.image}" alt="ph"></td>
      <td>${cartItem.name}${cartItem.free_shipping ? '<br/><em>Free shipping!</em>' : ''}<br/><span class="cart-item-price">$${(cartItem.price).toFixed(2)}</span></td>
      <td>
        <div class="catalogue-widget-container">
          <button type="button" class="btn catalogue-quantity-widget-btn less" ${cartItem.qty > 0 ? '' : 'disabled'}
                  data-update-cart="true" data-version="cart_v2" data-item-id="${cartItem.product_id}"><i class="fa fa-minus"></i></button>
          <div class="quantity-widget-amount" data-item-id="${cartItem.product_id}">${cartItem.qty}</div>
          <button type="button" class="btn catalogue-quantity-widget-btn more"
                  data-update-cart="true" data-version="cart_v2" data-item-id="${cartItem.product_id}"><i class="fa fa-plus"></i></button>
          <a onclick="updateCart('cart_v2', ${cartItem.product_id}, 0);" class="cart-item-remove"><i class="fa fa-times-circle"></i></a>
      </div>
    </td>
  </tr>`;
}

function createCartWarning(warning) {
  return `<tr id="cart_warning">
    <td colspan="3">
        <div class="alert alert-warning text-center">${warning}</div>
    </td>
  </tr>`;
}

// add to cart
function updateCart(version, itemId, quantity, callback) {
  $.post(`${project_prefix}ajax.php?ajax_component=cart&ajax_version=${version}`, { id: itemId, qty: quantity })
    .done((response) => {
      const cartWidget = $('#cartWidgetWrapper');
      const cartElement = $('#Cart');
      const isCartPage = cartElement && cartElement.length;

      // update existing cart widget
      if (cartWidget && cartWidget.length) {
        const cartItemQty = $(`#cartWidgetWrapper .quantity-widget-amount[data-item-id='${itemId}']`);
        if (cartItemQty && cartItemQty.length) {
          if (quantity > 0) {
            // update product quantity in cart
            cartItemQty.text(quantity);
          } else {
            $(`#cart_item_${itemId}`).remove();
          }
        } else {
          // append new cart item
          const item = response.cart.find((cartItem) => cartItem.product_id === itemId);
          if (item) {
            $('#cartWidgetWrapper .shop-widget tbody').append(createCartItem(item));
          } else {
            console.log(`Cannot find cart itemId "${itemId}"`);
          }
        }
        // update page widget
        const pageItemQty = $(`.quantity-widget-amount[data-item-id='${itemId}']`);
        if (pageItemQty && pageItemQty.length) {
          pageItemQty.text(quantity);
        }
        $('#cart_total_price').text(response.total_price.toFixed(2));
        if (!isCartPage) {
          cartWidget.show();
        }
      } else {
        // create cart widget. do not show if rendering page (itmId == undefined) or isCartPage = true
        let widget = `<div class="cart-dropdown-menu widgetMenu" id="cartWidgetWrapper" style="${(itemId && !isCartPage) ? '' : 'display: none;'}">
        <table class="table shop-widget">
        <tbody>
        <tr>
            <td colspan="2" class="text-left"><b>Your cart:</b></td>
            <td colspan="1" class="text-right"><i class="fa fa-times" onclick="$('#cartWidgetWrapper').toggle()"></i></td>
        </tr>`;
        if (response && response.cart && response.cart.length) {
          response.cart.forEach((item) => {
            widget += createCartItem(item);
          });
        }
        widget += `</tbody>
          </table>
          <div class="widget-total">Total: $<span id="cart_total_price">${response.total_price.toFixed(2)}</span></div>
          <div class="widget-checkout">
              <a href="/cart">Cart/Checkout</a> 
          </div>
        </div>`;

        $('#cartWidgetWrapper').remove();
        $(widget).prependTo(document.body);
      }

      const cartWarning = $('#cartWidgetWrapper .shop-widget tbody #cart_warning');
      cartWarning.remove();

      if (response.warning) {
        $('#cartWidgetWrapper .shop-widget tbody').append(createCartWarning(response.warning));
      }

      // update cart icon in navbar
      $('#mainWidgetWrapper').html(`<a class="nav-item nav-mobile" href="/cart">
          ${response.total_quantity > 0 ? `<div class="badge">${response.total_quantity}</div>` : ''}
          <i class="fa fa-shopping-cart"></i>
        </a>
        <a class="nav-item nav-desktop dropdown-toggle" onclick="$('#cartWidgetWrapper').toggle()">
          ${response.total_quantity > 0 ? `<div class="badge">${response.total_quantity}</div>` : ''}
          <i class="fa fa-shopping-cart"></i>
        </a>`);

      // update cart page
      if (isCartPage) {
        const cartPageItemQty = $(`#cart_page_item_qty_${itemId}`);
        if (cartPageItemQty && cartPageItemQty.length) {
          if (quantity > 0) {
            // update product quantity in cart
            cartPageItemQty.text(quantity);
          } else {
            $(`#cart_page_item_${itemId}`).remove();
          }
        }
        $('#cart_page_total_discount').text(`${response.percentage_discount.toFixed(0)} %`);
        $('#cart_page_total_price').text(`$${response.total_price.toFixed(2)}`);
        const cartPageWarning = $('#cart_page_warning');
        if (response.warning) {
          cartPageWarning.text(response.warning);
          cartPageWarning.show();
        } else {
          cartPageWarning.hide();
        }
        const cartPageCheckout = $('#cart_page_checkout');
        if (response.disable_checkout) {
          cartPageCheckout.hide();
        } else {
          cartPageCheckout.show();
        }
      }

      // finally call callback if present
      if (callback) {
        callback();
      }
    });
}

// default add to cart for single product page
$('#cart_button').click(() => {
  if ($('.shop-validate').parsley().validate()) {
    const btn = $('#cart_button');
    const itemId = btn.data('id');
    const quantity = parseInt(btn.siblings('.quantity-widget-amount').text(), 10);
    const version = btn.data('version');

    updateCart(version, itemId, quantity);

    return false;
  }
});

// master add to cart
$(document).on('click', '.cart_button', function () {
  const btn = $(this);
  const amount = btn.siblings('.quantity-widget-amount').text();
  const quantity = parseInt(amount, 10);
  const itemId = btn.data('id');
  const version = btn.data('version');

  btn.children('.fa').removeClass('fa-shopping-cart').addClass('fa-spinner fa-pulse');

  updateCart(version, itemId, quantity, () => {
    btn.children('.fa').removeClass('fa-spinner fa-pulse').addClass('fa-check');
    btn.next('.view-cart').removeClass('hide');
    btn.blur();
  });
});

function runFilters() {
  const version = $('#ajaxFilters').data('version');
  const ajax_url = `${project_prefix}ajax.php?ajax_component=catalogue&ajax_version=${version}`;

  $.post(ajax_url, $('#ajaxFilters').serialize())
    .done((data) => {
    // alert(data);

      $('.catalogue-col-2').html(data);

      // re-initialize lazy load here...
      $('.lazy').lazyload({
        effect: 'fadeIn',
      });
    });
}

$('#ajaxFilters input').on('click', () => {
  runFilters();
});

if ($('#ajaxFilters input:checked').length > 0) {
  runFilters();
}

// click tracking here
$('a, button').click(function () {
  if ((this.id.length > 0) || (this.title.length > 0)) {
    const clicklocal = window.location.href;
    const finishedUrl = clicklocal.split('#');

    $.get(`${project_prefix}cms/index.php`, { click_page: finishedUrl[0], click_title: this.title, click_id: this.id })
      .done(() => {
      });
  }
});

$(document).on('click', '.catalogue-quantity-widget-btn', function () {
  const btn = $(this);
  const itemId = btn.data('item-id');
  const allBtns = $(`.catalogue-quantity-widget-btn.${btn.hasClass('more') ? 'more' : 'less'}[data-item-id=${itemId}]`);
  const moreBtn = btn.hasClass('more') ? allBtns : allBtns.siblings('.more');
  const lessBtn = btn.hasClass('less') ? allBtns : allBtns.siblings('.less');

  // disable buttons to avoid double clicks
  moreBtn.attr('disabled', true);
  lessBtn.attr('disabled', true);

  // calculate new amount
  const amount = btn.siblings('.quantity-widget-amount').text();
  const newAmount = parseInt(amount, 10) + (btn.hasClass('more') ? 1 : -1);

  // add total to hover widget
  btn.siblings('.quantity-widget-amount').text(newAmount);

  // if this is the shopping cart, then update totals
  if (btn.data('update-cart') || btn.data('update-cart') === 'true') {
    const version = btn.data('version');
    updateCart(version, itemId, newAmount);
  }

  // enable the increase and decrease buttons if necessary
  moreBtn.attr('disabled', false);
  lessBtn.attr('disabled', newAmount < 1);
});

$(document).ready(() => {
  updateCart('cart_v2', null, 0, null);
});
